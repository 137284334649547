import styles from "pages/replacements-page-styles.module.css";
import { Grid } from "@mui/material";
import { OrderDetailViewModel } from "data/api/v1";
import {
	AccountNumberText,
	designerNumberText,
	DoorStyleText,
	FinishText,
	OrderNumberText,
	OrderReplacementOrderDetailLinkText,
	OrderReplacementsJobName,
	OrderReplacementsOrderType,
	OrderReplacementsPONumber,
	OrderReplacementsText,
	UnavailableDataPlaceholderText
} from "constants/text";
import { useEffect, useState } from "react";
import { useGetUserDetailsQuery } from "features/userApi";
import { UserAccountViewModel } from "data/api/v1/model/user-account-view-model";
import { brandCheck, displayDesigner } from "utils/order";
import { useLocation, useNavigate } from "react-router-dom";
import StyledSummaryCardLogo from "styles/StyledComponents/StyledSummaryCardLogo";
import ShoppingCartIcon from "./ShoppingCartIcon";
import LeavePageModal from "components/LeavePageModal/LeavePageModal";
import { useSelector } from "react-redux";
import { RootState } from "stores/application.store";
import { GenerateStepperWrapper } from "./ReplacementHeaderText.styles";
import { LinkButton } from "components/Common/Link";
import ProgressStepper from "../Common/Stepper/Stepper";

export interface ReplacementsHeaderProp {
	orderDetail: OrderDetailViewModel | null;
}
const ReplacementsHeader = ({ orderDetail }: ReplacementsHeaderProp) => {
	const { data: userDetails } = useGetUserDetailsQuery();
	const [userAccountInformation, setUserAccountInformation] = useState("");
	const userAccount: UserAccountViewModel[] | undefined = userDetails?.user?.accounts?.map((account: any) => account);

	const location = useLocation();
	const orderReplacementsPage = location.pathname.includes(`replacements/${orderDetail?.orderId}`);

	const cartItems = useSelector((state: RootState) => state.cart.replacements);
	const navigate = useNavigate();
	const [leavePageModalOpen, setLeavePageModalOpen] = useState(false);
	const handleLeavePageModalOpen = (url: string) => {
		if (cartItems && cartItems.length > 0) {
			setLeavePageModalOpen(!leavePageModalOpen);
		} else {
			navigate(url);
		}
	};

	useEffect(() => {
		userAccount?.forEach(
			(account: any) =>
				account.number === orderDetail?.accountNumber &&
				setUserAccountInformation(
					`${account.number}` + (account.description ? ` - ${account.description}` : "")
				)
		);
	});

	return (
		<div>
			<LeavePageModal
				open={leavePageModalOpen}
				url={`/details/${orderDetail?.orderId}`}
				continueOrdering={() => handleLeavePageModalOpen(`/details/${orderDetail?.orderId}`)}
				closeModal={() => setLeavePageModalOpen(false)}
			/>
			<Grid
				container
				className={styles.replacementsMainHeader}
			>
				<Grid item>
					<LinkButton
						id="order-details-my-orders-link"
						hasBackIcon
						onClick={() => handleLeavePageModalOpen(`/details/${orderDetail?.orderId}`)}
						data-testid="orderDetailsHeader-myOrdersLink"
					>
						{OrderReplacementOrderDetailLinkText}
					</LinkButton>
				</Grid>
				<ShoppingCartIcon showButtons={orderReplacementsPage} />
			</Grid>

			<Grid
				container
				className={styles.orderReplacementsHeaderContainer}
			>
				<Grid
					item
					xl={6}
				>
					<h4
						className={styles.replacementPageHeader}
						data-testid="replacements-header-text"
					>
						{OrderReplacementsText}
					</h4>
				</Grid>
				<GenerateStepperWrapper
					container
					xl={6}
					lg={7.5}
					md={8}
				>
					<ProgressStepper />
				</GenerateStepperWrapper>
			</Grid>

			<div className={styles.replacementsHeader}>
				<Grid
					container
					data-testid="replacements-header-content"
					rowSpacing={1.5}
					columnSpacing={{ md: 8 }}
				>
					<Grid
						item
						md="auto"
						xs={12}
					>
						<StyledSummaryCardLogo
							data-testid="replacements-header-logo"
							parentBrand={orderDetail?.parentBrand}
							src={
								brandCheck(orderDetail?.parentBrand)
									? process.env.PUBLIC_URL +
										`/assets/manufacture_logos/${orderDetail?.parentBrand}.png`
									: process.env.PUBLIC_URL + "/assets/tandem_logos/cwg_logo.png"
							}
							alt={
								brandCheck(orderDetail?.parentBrand)
									? String(orderDetail?.parentBrand)
									: "CabinetworksGroup Logo"
							}
						/>
					</Grid>

					<Grid
						item
						md="auto"
						xs={12}
					>
						<span className={`${styles["replacementsHeaderLabels"]} subtitle2`}>
							{OrderReplacementsJobName}
						</span>
						<span
							className="body2"
							data-testid="replacements-header-jobName"
						>
							{orderDetail?.jobName ?? UnavailableDataPlaceholderText}
						</span>
					</Grid>

					<Grid
						item
						md="auto"
						xs={12}
					>
						<span className={`${styles["replacementsHeaderLabels"]} subtitle2`}>
							{OrderReplacementsOrderType}
						</span>
						<span
							className="body2"
							data-testid="replacements-header-orderType"
						>
							{orderDetail?.orderType ?? UnavailableDataPlaceholderText}
						</span>
					</Grid>

					<Grid
						item
						md="auto"
						xs={12}
					>
						<span className={`${styles["replacementsHeaderLabels"]} subtitle2`}>
							{OrderReplacementsPONumber}
						</span>
						<span
							className="body2"
							data-testid="replacements-header-poNumber"
						>
							{orderDetail?.poNumber ?? UnavailableDataPlaceholderText}
						</span>
					</Grid>

					<Grid
						item
						md="auto"
						xs={12}
					>
						<span className={`${styles["replacementsHeaderLabels"]} subtitle2`}>{OrderNumberText}</span>
						<span
							className="body2"
							data-testid="replacements-header-orderNumber"
						>
							{orderDetail?.orderNumber ?? UnavailableDataPlaceholderText}
						</span>
					</Grid>

					<Grid
						item
						md="auto"
						xs={12}
					>
						<span className={`${styles["replacementsHeaderLabels"]} subtitle2`}>{designerNumberText}</span>
						<span
							className="body2"
							data-testid="replacements-header-designerNumber"
						>
							{displayDesigner(orderDetail?.designer) ?? UnavailableDataPlaceholderText}
						</span>
					</Grid>

					<Grid
						item
						md="auto"
						xs={12}
					>
						<span className={`${styles["replacementsHeaderLabels"]} subtitle2`}>{AccountNumberText}</span>
						<span
							className="body2"
							data-testid="replacements-header-accountNumber"
						>
							{(userAccountInformation.length ? userAccountInformation : orderDetail?.accountNumber) ||
								UnavailableDataPlaceholderText}
						</span>
					</Grid>

					<Grid
						item
						md="auto"
						xs={12}
					>
						<span className={`${styles["replacementsHeaderLabels"]} subtitle2`}>{DoorStyleText}</span>
						<span
							className="body2"
							data-testid="replacements-header-doorStyle"
						>
							{orderDetail?.doorStyle ?? UnavailableDataPlaceholderText}
						</span>
					</Grid>

					<Grid
						item
						md="auto"
						xs={12}
					>
						<span className={`${styles["replacementsHeaderLabels"]} subtitle2`}>{FinishText}</span>
						<span
							className="body2"
							data-testid="replacements-header-finish"
						>
							{orderDetail?.finish ?? UnavailableDataPlaceholderText}
						</span>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default ReplacementsHeader;
